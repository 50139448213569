export const AUTH_START = "AUTH_START";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_ENDS = "AUTH_ENDS";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const CLEAN_UP = "CLEAN_UP";

export const VERIFY_START = "VERIFY_START";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAIL = "VERIFY_FAIL";

export const RECOVERY_START = "RECOVERY_START";
export const RECOVERY_SUCCESS = "RECOVERY_SUCCESS";
export const RECOVERY_FAIL = "RECOVERY_FAIL";

export const FETCHING_CRYPTO_START = "FETCHING_CRYPTO_START";
export const FETCHING_CRYPTO_SUCCESS = "FETCHING_CRYPTO_SUCCESS";
export const FETCHING_CRYPTO_FAIL = "FETCHING_CRYPTO_FAIL";

export const SELL_TAB_ACTIVE = "SELL_TAB_ACTIVE";
export const SET_SELL_TAB = "SET_TAB_ACTIVE";
